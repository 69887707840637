import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import $ from 'jquery';
gsap.registerPlugin(ScrollTrigger);

const pageDefault = () => {

    // JavaScript to be fired on all pages

    $('[data-fancybox]').fancybox({
        buttons: [
            'zoom',
            // 'share',
            // 'slideShow',
            'fullScreen',
            'download',
            // 'thumbs',
            'close',
        ],
    });

    $('.js-references-slider').slick({
        autoplay: true,
        autoplaySpeed: 7500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: '<span class="reference_slider__arrow reference_slider__arrow--prev"></span>',
        nextArrow: '<span class="reference_slider__arrow reference_slider__arrow--next"></span>',
        touchMove: true,
    });

    // JavaScript to be fired on all pages
    $('.faq__filter .searchandfilter > ul > li li').addClass('checkcontainer').append('<span class="checkmark"></span>');

    if ($(window).width() < 992) {
        $('.faq__filter__title').click(function () {
            $('.faq__filter').toggleClass('faq__filter__open');
        });
    }

    $('.slick-slider').css('opacity', '1');

    $('.js-page-layer-more-btn').on('click', function (e) {
        e.preventDefault();
        const $content = $(this).parents('.js-page-layer-more-column').find('.js-page-layer-more-content');
        const innerHeight = $content.find('.js-page-layer-more-content-inner').height();

        $content.addClass('is-active').css('maxHeight', innerHeight);
        $(this).addClass('is-inactive');
    })

    $('.header__menu__btn').on('click',  function () {
        $('body').toggleClass('header__menu__open')
    })
    $('.header__menu .menu-item-has-children').append('<span class="menu-item-touch"></span>')
    $('.menu-item-touch').on('click', function () {
        $(this).parent('.menu-item').toggleClass('open')
    })

    if ($(window).width() > 767) {
        let scroll = $(window).scrollTop();

        $(window).scroll(function () {
            scroll = $(window).scrollTop();

            if (scroll > 120) {
                $('.header').addClass('header--fix');
            } else {
                $('.header').removeClass('header--fix');
            }
        });

        if (scroll > 120) {
            $('.header').addClass('header--fix');
        } else {
            $('.header').removeClass('header--fix');
        }
    }

    $('.header__search__toggle').on('click', function () {
        $('body').toggleClass('search__open');
        $(this).toggleClass('is-active');
        if ($('body').is('.search__open')) {
            $('.search__input').focus()
        }
    });

/* Start cookie */
function setCookie(cname, cvalue, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = 'expires='+d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

let popup = document.getElementById('dom__popup');

if (popup) {
    let popupName = popup.getAttribute('data-name');
    let popupClose = document.getElementById('dom__popup__close');
    let popupExpire = popup.getAttribute('data-reset');

    popupClose.onclick = function () {
        popup.style.display = 'none';
        setCookie(popupName, 'hide', (popupExpire ? popupExpire : 7))
    }
}
/* End cookie */

    function animateFrom(elem, delay)
    {
        gsap.to(elem, {
            duration: 1.25,
            delay: delay,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: 'expo',
            overwrite: 'auto',
        });
    }

    function animateScale(elem, delay)
    {
        gsap.to(elem, {
            duration: 1.25,
            delay: delay,
            scale: 1,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: 'expo',
            overwrite: 'auto',
        });
    }

    function animateScaleOutY(elem, delay)
    {
        gsap.to(elem, {
            duration: 1.25,
            delay: delay,
            scaleX: 1,
            scaleY: 0,
            ease: 'expo',
            overwrite: 'auto',
        });
    }

    function animateScaleOutX(elem, delay)
    {
        gsap.to(elem, {
            duration: 1.25,
            delay: delay,
            scaleX: 0,
            scaleY: 1,
            ease: 'expo',
            overwrite: 'auto',
        });
    }

    function getTriggerStart()
    {
        let triggerStart = 'top 80%';
        if ($(window).width() < 768) {
            triggerStart = 'top 100%';
        }
        return triggerStart;
    }

    function splitText(string)
    {
        string = string.split(' ');
        return string;
    }

    let section = document.querySelectorAll('.animation__section');
    if (section.length !== false && section) {
        section.forEach((currentSection) => {
            let timeTitle = 0;
            let timeText = 0;
            let timeBlock = 0;
            let timeImage = 0;
            let timePseudo = 0;

            currentSection.querySelectorAll('.title__reveal, .text__reveal, .image__reveal, .block__reveal, .pseudo__reveal').forEach((elem) => {
                if (elem.classList.contains('title__reveal')) {
                    const $titles = elem.querySelectorAll('.h1, .h2, .h3, .h4, .h5, .h6');

                    $.each($titles, function () {
                        const $title = $(this);
                        const string = $title.text();
                        const splitString = splitText(string);

                        $title.html('');
                        $.each(splitString, function (index, html) {
                            $title.append('<span class="title__reveal--animation">' + html + '&nbsp</span>');
                        });
                        $title.parent().addClass('is-active');
                    });

                    const titleWord = elem.querySelectorAll('span');

                    $.each(titleWord, function () {
                        const $word = $(this);

                        ScrollTrigger.create({
                            trigger: currentSection,
                            start: getTriggerStart(),
                            once: true,
                            onEnter: function () {
                                animateFrom($word, timeTitle);
                                timeTitle = timeTitle + 0.2;
                            },
                        });
                    });
                }

                if (elem.classList.contains('text__reveal')) {
                    ScrollTrigger.create({
                        trigger: currentSection,
                        start: getTriggerStart(),
                        once: true,
                        makers: true,
                        onEnter: function () {
                            animateFrom(elem, timeText);
                            timeText = timeText + 0.3;
                        },
                    });

                }

                if (elem.classList.contains('block__reveal')) {
                    ScrollTrigger.create({
                        trigger: currentSection,
                        start: getTriggerStart(),
                        once: true,
                        makers: true,
                        onEnter: function () {
                            animateFrom(elem, timeBlock);
                            timeBlock = timeBlock + 0.3;
                        },
                    });

                }

                if (elem.classList.contains('image__reveal')) {
                    ScrollTrigger.create({
                        trigger: currentSection,
                        start: getTriggerStart(),
                        once: true,
                        makers: true,
                        onEnter: function () {
                            animateScale(elem, timeImage);
                            timeImage = timeImage + 0.3;
                        },
                    });
                }

                if (elem.classList.contains('pseudo__reveal--x')) {
                    ScrollTrigger.create({
                        trigger: currentSection,
                        start: getTriggerStart(),
                        once: true,
                        makers: true,
                        onEnter: function () {
                            animateScaleOutX(elem, timePseudo);
                            timePseudo = timePseudo + 0.3;
                        },
                    });
                }

                if (elem.classList.contains('pseudo__reveal--y')) {
                    ScrollTrigger.create({
                        trigger: currentSection,
                        start: getTriggerStart(),
                        once: true,
                        makers: true,
                        onEnter: function () {
                            animateScaleOutY(elem, timePseudo);
                            timePseudo = timePseudo + 0.3;
                        },
                    });
                }
            });
        });
    }
}

export default pageDefault;
