import $ from 'jquery';

const teamComponents = (container) => {
    $('.team__content__post .team__content__title').matchHeight();

    container.querySelectorAll('.page__layer--team').forEach((elem) => {
        if (!elem.classList.contains('slick-initialized')) {
            const slick_args = {
                infinite: true,
                slidesToShow: 5,
                swipeToSlide: true,
                slidesToScroll: 1,
                pauseOnHover: false,
                dots: false,
                arrows: false,
                autoplay: false,
                adaptiveHeight: true,
                autoplaySpeed: 5000,
                rows: 1,
                responsive: [
                    {
                        breakpoint: 1399,
                        settings: {
                            slidesToShow: 4,
                        },
                    },
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            infinite: false,
                            slidesToShow: 1,
                        },
                    },
                ],
            };

            $(elem).slick(slick_args)
        }
    });
}

export default teamComponents;
