import barba from '@barba/core';

// Page
import defaultJs from './util/default';
import pageLeave from './pageTransitions/pageLeave';
import pageEnter from './pageTransitions/pageEnter';
import pageHomeEnter from './pageTransitions/pageHomeEnter';
import pageDefault from './pageAnimations/pageDefault';
import teamComponents from './pageAnimations/teamComponents';
import headerLogo from './pageAnimations/logo';
import themeMintygreen from './pageTransitions/themeMintygreen';
import themeBeaublue from './pageTransitions/themeBeaublue';

export default {
    init() {
        barba.init({
            debug: false,
            // define a custom function that will prevent Barba
            // from working on links that contains a `prevent` CSS class
            prevent: ({ el }) => el.classList && el.classList.contains('prevent'),

            transitions: [
                {
                    leave: ({current}) => pageLeave(current.container),
                    enter({next}) {
                        pageEnter(next.container).eventCallback('onComplete', function () {
                            headerLogo(next.container);
                            pageDefault();
                        });
                        teamComponents(next.container);
                    },
                    after() {
                        defaultJs();
                    },
                    once(data) {
                        pageEnter(data.next.container).eventCallback('onComplete', function () {
                            headerLogo(data.next.container);
                            pageDefault();
                        });
                        teamComponents(data.next.container);
                    },
            },
                {
                    name: 'home',
                    to: {
                        namespace: ['home'],
                    },
                    leave: ({current}) => pageLeave(current.container),
                    enter({next}) {
                        themeBeaublue(next.container);
                        pageHomeEnter(next.container);
                        teamComponents(next.container);
                        headerLogo(next.container);
                        setTimeout(function () {
                            pageDefault();
                        }, 100);
                    },
                    after() {
                        defaultJs();
                    },
                    once(data) {
                        themeBeaublue(data.next.container);
                        pageHomeEnter(data.next.container);
                        teamComponents(data.next.container);
                        headerLogo(data.next.container);
                        setTimeout(function () {
                            pageDefault();
                        }, 100);
                    },
            },
                {
                    name: 'theme_mintygreen',
                    to: {
                        namespace: ['theme_mintygreen'],
                    },
                    leave: ({current}) => pageLeave(current.container),
                    enter({next}) {
                        themeMintygreen(next.container);
                        teamComponents(next.container);
                        headerLogo(next.container);
                        setTimeout(function () {
                            pageDefault();
                        }, 100);
                    },
                    after() {
                        defaultJs();
                    },
                    once(data) {
                        themeMintygreen(data.next.container);
                        teamComponents(data.next.container);
                        headerLogo(data.next.container);
                        setTimeout(function () {
                            pageDefault();
                        }, 100);
                    },
                },

                {
                    name: 'theme_beaublue',
                    to: {
                        namespace: ['theme_beaublue'],
                    },
                    leave: ({current}) => pageLeave(current.container),
                    enter({next}) {
                        themeBeaublue(next.container);
                        teamComponents(next.container);
                        headerLogo(next.container);
                        setTimeout(function () {
                            pageDefault();
                        }, 100);
                    },
                    after() {
                        defaultJs();
                    },
                    once(data) {
                        themeBeaublue(data.next.container);
                        teamComponents(data.next.container);
                        headerLogo(data.next.container);
                        setTimeout(function () {
                            pageDefault();
                        }, 100);
                    },
                },
            ],
        });
    },
}
