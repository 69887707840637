import { ScrollTrigger } from 'gsap/ScrollTrigger';

const defaultJS = () => {

    // Reset: scrolltrigger
    ScrollTrigger.refresh();

    // Reset: bodyclass
    let bodyClassList = document.getElementById('body__class').classList;
    let body = document.getElementsByTagName('body')
    body[0].className = bodyClassList;


    // Reset: Scroll to top
    window.scrollTo(0,0)
}

export default defaultJS;
