import gsap from 'gsap';

const pageLeave = (container) => {

    const tl = gsap.timeline();

    const windowWidth = $(window).width();

    let body = document.getElementsByTagName('body')[0];
    let main = container.querySelector('.main__content')
    let header = container.querySelector('.header--fix')
    let footer = container.querySelector('.footer')
    let homeSection = container.querySelector('.home__section');
    let logoSlogan = container.querySelector('.header__logo__entity');

    if (homeSection && homeSection != null) {
        tl
            .to(container, { pointerEvents: 'none', userSelect: 'none', duration: 0}, 0)
            .to(main, { autoAlpha: 0 }, 0)
            .to(logoSlogan, {autoAlpha: 0}, 0);
    } else {
        if ( windowWidth < 992 ) {
            tl
                .to(container, {pointerEvents: 'none', userSelect: 'none', duration: 0}, 0)
                .to(container, {autoAlpha: 0}, 0)
                .to(logoSlogan, {autoAlpha: 0}, 0);

                setTimeout(function () {
                    body.classList.remove('header__menu__open');
                }, 200);
        } else {
            tl
                .to(container, {pointerEvents: 'none', userSelect: 'none', duration: 0}, 0)
                .to(main, {autoAlpha: 0}, 0)
                .to(header, {backgroundColor: '#ffffff', boxShadow: 'none'}, 0)
                .to(footer, {autoAlpha: 0}, 0)
                .to(logoSlogan, {autoAlpha: 0}, 0);
        }
    }

    return tl;

}

export default pageLeave;
