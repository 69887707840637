import gsap from 'gsap';

const pageEnter = (container) => {

    const tl = gsap.timeline();
    let main = container.querySelector('.main__content')

    tl
        .to(main, { autoAlpha: 1 }, '<')
        .to(container, { pointerEvents: 'all', userSelect: 'auto'})


    return tl;


}

export default pageEnter;
