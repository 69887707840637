import gsap from 'gsap';

const themeBeaublue = (container) => {

    const tl = gsap.timeline();
    let main = container.querySelector('.main__content')

    tl
        .to(main, { backgroundColor: '#d1e8f8', autoAlpha: 1}, 0)
        .to(container, { pointerEvents: 'all', userSelect: 'auto'})

    return tl;


}

export default themeBeaublue;
