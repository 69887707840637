import gsap from 'gsap';

const headerLogo = (container) => {
    const tl = gsap.timeline();
    let logoSlogan = container.querySelector('.header__logo__entity');

    // Todo: Add position animations?
    tl.to(logoSlogan, {autoAlpha: 1}, 0);
}

export default headerLogo;
